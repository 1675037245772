<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import vuexInit from "./utils/vuexInit";
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
export default {
  name: "App",
  components: {
  },
  data(){
    return{
      isWhiteBg:false,
    }
  },
  watch: {
    $route() {
     
    },
  },
  mounted() {
    
  },
  methods: {
   
  },
};
</script>

<style lang="scss">
$themeColor: #2e66d0;

.header{
  width: 100%;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
    background: #fff;
    height: 64px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgb(255, 255, 255);
    filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.06));
    &::before {
      content: "";
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      background: rgb(255, 255, 255);
      height: 100%;
      top: 0;
      left: 0;
      width: 100%;
      position: absolute;
      z-index: -1;
    }
    .w1200{
      width: 800px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      a{
        cursor: pointer;
      }
    }
}
.tip {
  display: none;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis_two {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}
.ellipsis_three {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}
.van-image__loading-icon,
.van-image__error {
  width: 100%;
  height: 100%;
  .van-icon__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.emptySmall .van-empty__image {
  width: 100px;
  height: 100px;
}
.emtpyMain {
  margin: calc((100vh - 230px) * 0.5 - 160px) auto 0;
}
body,
p,
div {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #fafafa;
  color: #333333;
}

a {
  text-decoration: none;
  color: #666;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}

#app {
  min-width: 1200px;
}

.one-row-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
