import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from '@/store/' 
Vue.use(store)

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const baseRoutes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/Index.vue'),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/Index.vue'),
  },
  {
    path: '/detail2',
    name: 'detail2',
    meta: { 
      // requireAuth: true

     },
    component: () => import('@/views/Detail2.vue'),
  },
  {
    path: '/player2',
    name: 'player2',
    meta: { 
      requireAuth: true

     },
    component: () => import('@/views/Player2.vue'),
  },
  {
    path: "*",
    name: "404",
    redirct: "/404",
    component: () => import("@/views/NotFound.vue"),
    hidden: true
  }
]

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    console.log('111')
    window.scrollTo(0, 0);
  },
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: baseRoutes
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  console.log(to)
    next()
    NProgress.done();

})

export { router, baseRoutes }
